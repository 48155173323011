import styled from 'styled-components'
import { spacing } from '../../../utils/mixins'
import arrowIcon from '../../../../static/arrow.svg'

export const StyledModalNavigation = styled.nav`
  display: flex;

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-width: 100%;
    width: 100%;

    li {
      width: 100%;

      &:first-child {
        a {
          padding-top: ${spacing(3)};
        }
      }

      &:last-child {
        a {
          padding-bottom: ${spacing(3)};
        }
      }

      a {
        font-size: 18px;
        line-height: 25px;
        color: #222222;
        display: flex;
        justify-content: space-between;
        padding: ${spacing(1, 0)};
        text-decoration: none;
        position: relative;
        transition: opacity 300ms;

        &::after {
          background-image: url(${arrowIcon});
          background-size: 14px 7px;
          background-repeat: no-repeat;
          background-position: center;
          content: '';
          line-height: 25px;
          height: 25px;
          width: 14px;
          transform: rotate(-90deg);
        }

        &:hover {
          opacity: 0.5;
        }

        &.active {
          cursor: default;
          opacity: 0.5;
        }
      }
    }
  }
`
