import { useState, useEffect } from 'react'

const useHashLinks = () => {
  const [selectedHash, setSelectedHash] = useState()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('smooth-scroll')
        .then(m => {
          m.default('a[href*="#"]')
        })
        .catch(err => console.warn(err))
    }
  }, [])

  return { selectedHash, setSelectedHash }
}

export default useHashLinks
