import { graphql } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AsideTitle from '../../components/aside/title'
import { AsideLinks, AsideLink } from '../../components/aside/links-list'
import useHashLinks from '../../hooks/useHashLinks'
import { StyledNextPage, StyledPageTitle } from './styledComponents'
import {
  StyledContainer,
  StyledArticle,
  StyledAside,
} from '../../components/blog/styledComponents'
import { ImageFigure, MarkdownText, VideoFigure } from '../../components/blog'
import { ModalHeader, Modal, ModalBody } from '../../components/modal'
import ServicesModalNavigation from './services-modal-navigation'
import ServicesNavigation from './services-navigation'

export const query = graphql`
  query ServiceQuery($id: ID!) {
    strapiGraphql {
      service(id: $id) {
        slug
        pageTitle
        anchorsTitle
        items {
          ... on StrapiGraphQL_ComponentContentRichText {
            id
            text
          }
          ... on StrapiGraphQL_ComponentContentUploads {
            id
            media {
              mediaFile {
                childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              url
              caption
              alternativeText
            }
          }
          ... on StrapiGraphQL_ComponentContentAnchor {
            id
            anchorHash
            anchorTitle
            targetTitle
          }
        }
      }
    }
  }
`

export default function Service({ pageContext, data, path, location }) {
  const { allServices, index, pageSeo } = pageContext
  const nextService = allServices[index + 1]
  const content = data.strapiGraphql.service
  const anchors = content.items.filter(
    item => typeof item.anchorHash !== 'undefined',
  )

  const [openModal, setOpenModal] = useState(false)
  const handleModalState = () => setOpenModal(v => !v)

  const { selectedHash, setSelectedHash } = useHashLinks()

  return (
    <Layout>
      <SEO {...pageSeo} />

      <StyledContainer>
        <ServicesNavigation
          items={allServices}
          path={path}
          onButtonClick={handleModalState}
        />

        <StyledPageTitle>{content.pageTitle}</StyledPageTitle>

        <StyledArticle>
          {content.items.map(item => {
            if (typeof item.text !== 'undefined') {
              return <MarkdownText key={item.id} text={item.text} />
            } else if (typeof item.media !== 'undefined') {
              return item.media.map(
                ({ mediaFile, url: key, alternativeText, caption }) => {
                  if (mediaFile.childImageSharp) {
                    return (
                      <ImageFigure
                        key={key}
                        alt={alternativeText}
                        caption={caption}
                        fluid={mediaFile.childImageSharp.fluid}
                      />
                    )
                  } else if (mediaFile.publicURL) {
                    return <VideoFigure key={key} src={mediaFile.publicURL} />
                  }
                },
              )
            } else if (typeof item.anchorHash !== 'undefined') {
              const { anchorHash, targetTitle } = item
              return (
                <h4 id={anchorHash} key={anchorHash}>
                  {targetTitle}
                </h4>
              )
            }
          })}

          {nextService && (
            <StyledNextPage to={`/services/${nextService.slug}`}>
              <small>следующее</small>
              {nextService.pageTitle}
            </StyledNextPage>
          )}
        </StyledArticle>

        {Boolean(anchors.length) && (
          <StyledAside>
            <AsideTitle>{content.anchorsTitle}</AsideTitle>
            <AsideLinks>
              {anchors.map(({ anchorHash, anchorTitle, id }) => (
                <AsideLink
                  key={id}
                  hash={anchorHash}
                  isActive={anchorHash === selectedHash}
                  onClick={() => setSelectedHash(anchorHash)}
                >
                  {anchorTitle}
                </AsideLink>
              ))}
            </AsideLinks>
          </StyledAside>
        )}

        <Modal open={openModal} onClose={handleModalState}>
          <ModalBody>
            <ModalHeader onClose={handleModalState}>Виды услуг</ModalHeader>
            <ServicesModalNavigation items={allServices} />
          </ModalBody>
        </Modal>
      </StyledContainer>
    </Layout>
  )
}
