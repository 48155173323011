import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StyledNavigation } from './styledComponents'

const ServicesNavigation = ({ items, path, onButtonClick }) => {
  const getServicePath = slug => `/services/${slug}`
  const activeLink = items.find(({ slug }) => getServicePath(slug) === path)

  return (
    <StyledNavigation>
      <ul>
        <li>
          <h6>
            Виды&nbsp;
            <br />
            услуг:
          </h6>
        </li>

        {items.map(({ slug, pageTitle, id }) => {
          return (
            <li key={id}>
              <Link
                to={getServicePath(slug)}
                activeClassName='active'
                partiallyActive={true}
              >
                {pageTitle}
              </Link>
              <span className='border' />
            </li>
          )
        })}
      </ul>

      <button type='button' onClick={onButtonClick}>
        <span>{activeLink ? activeLink.pageTitle : ''}</span>
      </button>
    </StyledNavigation>
  )
}

ServicesNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
    }),
  ),
  path: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
}

export default ServicesNavigation
