import styled, { css } from 'styled-components'
import { spacing, device } from '../../../utils/mixins'
import arrowIcon from '../../../../static/arrow.svg'

const desktopNavigationStyles = css`
  @media ${device.tablet} {
    margin-bottom: ${spacing(2)};
  }

  @media ${device.tabletLand}, ${device.desktop} {
    margin-bottom: ${spacing(4)};
  }

  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    border-bottom: 2px solid #f0f0f0;
    flex: 0 0 100%;
    padding: ${spacing(3, 0)};

    button {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;

      li {
        border: 2px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: ${spacing(1, 2)};
        transition: all 300ms;

        &:first-child {
          border: none;
          display: flex;
          padding: 0;
          margin-right: ${spacing(2)};

          h6 {
            font-size: 11px;
            font-weight: 700;
            line-height: 15px;
            text-transform: uppercase;
            margin: 0;

            br {
              display: none;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          color: #0074b8;
          font-size: 14px;
          line-height: 14px;
          text-decoration: none;
          transition: opacity 300ms;

          &:hover {
            opacity: 0.6;
          }

          &.active {
            color: #999999;
            cursor: default;

            &:hover {
              opacity: 1;
            }

            & + .border {
              border-color: rgba(153, 153, 153, 0.3);
            }
          }

          & + .border {
            display: block;
            border: 1px solid transparent;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
          }
        }
      }
    }
  }
`

const mobileNavigationStyles = css`
  @media ${device.phone} {
    display: flex;
    flex: 0 0 calc(100% + ${spacing(5)});
    max-width: calc(100% + ${spacing(5)});
    border-bottom: 2px solid #e0e0e0;
    padding: 16px 20px;
    margin-left: -20px;
    margin-right: -20px;

    button {
      background-color: transparent;
      border: none;
      font: 400 18px/25px Tenor, sans-serif;
      color: #222222;
      cursor: pointer;
      display: block;
      text-decoration: none;
      position: relative;
      padding: 0;
      text-align: left;
      width: 100%;
      max-width: calc(100% - 46px);

      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 12px);
      }

      &::after {
        background-image: url(${arrowIcon});
        background-size: 12px 6px;
        background-repeat: no-repeat;
        background-position: center;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 12px;
      }

      &:focus {
        outline: none;
      }
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: none;

        &:first-child {
          display: flex;
          margin-right: ${spacing(2)};

          h6 {
            font-size: 10px;
            font-weight: 400;
            line-height: 10px;
            margin: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }
`

export const StyledNavigation = styled.nav`
  ${mobileNavigationStyles}
  ${desktopNavigationStyles}
`
