import styled from 'styled-components'
import { spacing, device } from '../../utils/mixins'
import { Link } from 'gatsby'
import { PageTitle } from '../../components/blog'

export const StyledPageTitle = styled(PageTitle)`
  display: none;

  @media ${device.tabletLand}, ${device.desktop} {
    display: block;
  }
`

export const StyledNextPage = styled(Link)`
  font: 400 18px/25px Tenor, sans-serif;
  display: flex;
  flex-direction: column;
  color: #0074b8;
  text-decoration: none;
  margin: ${spacing(2, 0)};

  &::before {
    content: none !important;
  }

  small {
    font: 400 10px/15px Geometria, sans-serif;
    text-transform: lowercase;

    @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
      font-size: 12px;
    }
  }
`
