import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { StyledModalNavigation } from './styledComponents'

const ServiceModalNavigation = ({ items }) => {
  return (
    <StyledModalNavigation>
      <ul>
        {items.map(({ id, slug, pageTitle }) => (
          <li key={id}>
            <Link to={`/services/${slug}`} activeClassName='active'>
              {pageTitle}
            </Link>
          </li>
        ))}
      </ul>
    </StyledModalNavigation>
  )
}

ServiceModalNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
      pageTitle: PropTypes.string,
    }),
  ).isRequired,
}

export default ServiceModalNavigation
